import * as React from 'react';

import { Slide } from 'react-awesome-reveal';

export const SlideWrapper = (props: any) => {
  const tablMob = window && window.innerWidth <= 1024 ? true : false;
  if (tablMob) {
    return <>{props.children}</>;
  } else {
    return <div className="page">{props.children}</div>;
  }
};
