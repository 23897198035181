import moment from 'moment';
import Highcharts from 'highcharts';

export const getChartOptions = (utilTheme: string, unit: string, max?: number, type?: string) => {
  let fontSize = window.innerWidth > 1920 ? '1.4rem' : '14px';
  let marginBottom = window.innerWidth > 1920 ? 60 : 30;
  let fontWeight = '500';

  return {
    chart: {
      marginTop: 50,
      marginBottom: marginBottom,
      type: 'line',
    },
    title: {
      text: '',
    },
    colors: [`rgb(${utilTheme})`],
    plotOptions: {
      bar: {
        stacking: 'normal',
        maxPointWidth: 50,
      },
      column: {
        pointPadding: 0.1,
        groupPadding: 0,
        borderWidth: 0,
        shadow: false,
      },
      series: {},
      line: {
        lineWidth: 4,
        marker: {
          radius: 3,
          lineColor: `rgb(${utilTheme})`,
          lineWidth: 2,
          fillColor: '#fff',
        },
      },
    },
    xAxis: {
      type: 'datetime',
      title: {
        enabled: false,
      },
      gridLineWidth: 0,
      // alignTicks: false,
      tickInterval: 3600 * 1000,
      lineWidth: 1,
      lineColor: '#666',
      labels: {
        style: {
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: '#444',
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      min: 0,
      gridLineWidth: 0,
      max: max,
      lineWidth: 1,
      lineColor: '#666',
      showEmpty: true,
      labels: {
        format: `{value:,.0f}'${unit}`,
        formatter: function () {
          const str = this.value.toString();
          const dp = str.indexOf('.') > -1 ? str.substring(str.indexOf('.'), str.length - 1).length : 0;
          return Highcharts.numberFormat(this.value, dp, '.', ',') + unit;
        },
        style: {
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: '#444',
        },
      },
    },
    time: {
      useUTC: false,
      moment: moment,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      y: 30,
      itemMarginBottom: 5,
      padding: 12,
      symbolPadding: 12,
      itemStyle: {
        fontWeight: fontWeight,
        fontSize: fontSize,
        color: '#444',
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [],
  };
};

export default getChartOptions;
