import * as React from 'react';
import styles from './footer.module.scss';

export const Footer = ({ footerLeftImage, footerRightImage }) => {
  return (
    <footer className={styles.footer}>
      <div style={{ backgroundImage: `url(${footerLeftImage})` }} />

      <div style={{ backgroundImage: `url(${footerRightImage})` }} />
    </footer>
  );
};

export default Footer;
