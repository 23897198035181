import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Player } from '@lottiefiles/react-lottie-player';

import styles from './no-data-tile.module.scss';

interface NoDataTileProps {
  color: string;
}

export const NoDataTile = ({ color }: NoDataTileProps) => {
  return (
    <div className={`tile no-data-tile ${styles.noDataTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      <Fade>
        <Player autoplay loop src="/no-data.json"></Player>
        <h3>Sorry, no data just yet...</h3>
      </Fade>
    </div>
  );
};
