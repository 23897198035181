import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import { UsageDto } from 'utilities';
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { ReactComponent as GasIcon } from 'assets/gas2.svg';
import { ReactComponent as BathIcon } from 'assets/bathtub.svg';
import styles from './equivalent-tile.module.scss';

interface EquivalentTileProps {
  color: string;
  type: string;
  usage: UsageDto;
  activeChart: string;
  running?: Boolean;
}

export const nearstHighSqrt = (n: number) => {
  if (Math.sqrt(n) % 1 === 0) {
    return n;
  }
  return nearstHighSqrt(++n);
};

export const EquivalentTile = ({ color, type, usage, activeChart, running }: EquivalentTileProps) => {
  let theType = 'daily';
  if (activeChart === 'daily') {
    theType = 'weekly';
  } else if (activeChart === 'monthly') {
    theType = 'annually';
  }
  const equivalentTo = usage?.equivalentTo?.value ? usage?.equivalentTo?.value[theType] : null;
  return (
    <div className={`tile  ${styles.equivalentTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      <Fade>
        <div className={`large-text ${styles.largeText}`}>
          {equivalentTo.toLocaleString()}
          <span>{usage?.equivalentTo?.uom || '-'}</span>
        </div>
        <div className={`${styles.largeIcon} ${styles[type]}`}>
          {type === 'water' ? <BathIcon /> : type === 'gas' ? <GasIcon /> : <HomeIcon />}
        </div>

        <div className={styles.textContent}>
          <div className={`large-text ${styles.textContentA}`}>Equivalent</div>
          <label className={`small-text ${styles.textContentB}`}>Annual</label>
        </div>
      </Fade>
    </div>
  );
};
