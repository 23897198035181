import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import { AppContext, CategoryDto, DataSetDto, UtilitiesDto } from 'utilities';
import {
  SlideWrapper,
  Box,
  LineChartComponent,
  UsageTile,
  EquivalentTile,
  LoadingTile,
  HorizontalBarChart,
  VerticalBarChart,
  NoDataTile,
  WeatherTile,
  WindTile,
  ForecastTile,
} from 'components';

export const HomePage = ({}) => {
  const { utilities, showWeather, activeChart } = React.useContext(AppContext);

  const amountOfItems = utilities?.length + (showWeather ? 1 : 0) || 0;
  const oneItem = amountOfItems === 1;
  const twoItems = amountOfItems === 2;
  const threeItems = amountOfItems === 3;
  const fourItems = amountOfItems === 4;

  if (!utilities || utilities.indexOf(undefined) > -1) {
    return null;
  }

  return (
    <SlideWrapper>
      <div
        className={`home-content ${oneItem ? 'one-item' : ''} ${twoItems ? 'two-items' : ''}  ${
          threeItems ? 'three-items' : ''
        } ${fourItems ? 'four-items' : ''}`}>
        {utilities?.map((util, i) => (
          <HomeBox key={i} util={util} />
        ))}
        {showWeather && <WeatherBox activeChart={activeChart} />}
      </div>
    </SlideWrapper>
  );
};

export const HomeBox = ({ util }: any) => {
  const { metrics, loadingUsages, loadingConsumptions, activeChart, resizing, running } = React.useContext(AppContext);
  const allProps = React.useContext(AppContext);
  const utilTheme = allProps[`${util}Color`].join() as string;
  const noDataCopy = 'Sorry, no data just yet...';
  const metric = metrics?.length > 0 ? metrics.find((x) => x?.type === util?.toLowerCase()) : null;
  let chartTitle = 'Last ';
  if (activeChart === 'hourly') {
    chartTitle += '24 hours usage';
  } else if (activeChart === 'daily') {
    chartTitle += '7 days usage';
  } else if (activeChart === 'monthly') {
    chartTitle += '12 months usage';
  } else {
    chartTitle = 'By type in the last 24 hours';
  }

  const hasUsage = !!metric?.usage?.unit;
  let hasChartData = false;
  if (activeChart === 'categories') {
    let consumptionData = metric && metric[activeChart] ? (metric[activeChart] as CategoryDto[]) : null;
    hasChartData = consumptionData && consumptionData.length > 0 && consumptionData[0] ? true : false;
    if (hasChartData) {
      let hasNonZeros = true;
      consumptionData.forEach((cons) => {
        const usage = cons.data.reduce((a, b) => a + (b.usage || 0), 0);
        if (usage !== 0) {
          hasNonZeros = true;
        }
      });
      if (hasNonZeros) {
        hasChartData = true;
      }
    }
  } else {
    let consumptionData = metric && metric[activeChart] ? (metric[activeChart] as DataSetDto) : null;
    hasChartData = consumptionData && consumptionData.actuals && consumptionData.actuals.length > 0 ? true : false;
    if (hasChartData) {
      const amountOfZeros = consumptionData.actuals.filter((x) => x.usage === 0);
      if (amountOfZeros.length === consumptionData.actuals.length) {
        hasChartData = false;
      }
    }
  }

  return (
    <Box key={util} color={utilTheme}>
      <div className={`tiles`}>
        {hasUsage ? (
          <>
            <UsageTile color={utilTheme} type={util} usage={metric.usage} activeChart={activeChart} />
            <EquivalentTile
              color={utilTheme}
              type={util}
              usage={metric.usage}
              running={running}
              activeChart={activeChart}
            />
          </>
        ) : loadingUsages ? (
          <>
            <LoadingTile color={utilTheme} />
            <LoadingTile color={utilTheme} />
          </>
        ) : (
          <>
            <LoadingTile color={utilTheme} copy={noDataCopy} />
            <LoadingTile color={utilTheme} copy={noDataCopy} />
          </>
        )}
      </div>

      <div className={`chart`}>
        {loadingUsages || loadingConsumptions || resizing ? (
          <>
            <LoadingTile color={'255,255,255'} />
          </>
        ) : !hasChartData ? (
          <NoDataTile color={'#FFF'} />
        ) : (
          <Fade className={`chartContent `}>
            <>
              <div className={'chartTitle'}>{chartTitle}</div>
              {activeChart === 'monthly' ? (
                <VerticalBarChart utilType={util} utilTheme={utilTheme} />
              ) : activeChart === 'daily' ? (
                <VerticalBarChart utilType={util} utilTheme={utilTheme} />
              ) : activeChart === 'categories' ? (
                <HorizontalBarChart utilType={util} utilTheme={utilTheme} />
              ) : (
                <LineChartComponent utilType={util} utilTheme={utilTheme} />
              )}
            </>
          </Fade>
        )}
      </div>
    </Box>
  );
};

export const WeatherBox = ({ activeChart }) => {
  const { loadingWeather, weather, resizing, weatherColor } = React.useContext(AppContext);
  const utilTheme = weatherColor.join() as string;
  const noDataCopy = 'Sorry, no data just yet...';
  const hasWeather = weather?.city && weather?.events.length > 0 ? true : false;

  // feels like
  // current
  // humidity
  // temp
  // windspeed
  return (
    <Box color={utilTheme}>
      <div className={`tiles`}>
        {hasWeather ? (
          <>
            <WeatherTile color={utilTheme} weather={weather} activeChart={activeChart} />
            <WindTile color={utilTheme} weather={weather} />
          </>
        ) : loadingWeather ? (
          <>
            <LoadingTile color={utilTheme} />
            <LoadingTile color={utilTheme} />
          </>
        ) : (
          <>
            <LoadingTile color={utilTheme} copy={noDataCopy} />
            <LoadingTile color={utilTheme} copy={noDataCopy} />
          </>
        )}
      </div>

      <div className={`chart chart-forecast`}>
        {loadingWeather || resizing ? (
          <>
            <LoadingTile color={'255,255,255'} />
          </>
        ) : !hasWeather ? (
          <NoDataTile color={'#FFF'} />
        ) : (
          <ForecastTile color={utilTheme} weather={weather} />
        )}
      </div>
    </Box>
  );
};
