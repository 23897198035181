import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import moment from 'moment';
import { UsageDto } from 'utilities';
import { ReactComponent as ElectricityIcon } from 'assets/electricity.svg';
import { ReactComponent as GasIcon } from 'assets/gas.svg';
import { ReactComponent as WaterIcon } from 'assets/water.svg';

import styles from './usage-tile.module.scss';

interface UsageTileProps {
  color: string;
  type: string;
  activeChart: string;
  usage: UsageDto;
}

export const UsageTile = ({ color, type, usage, activeChart }: UsageTileProps) => {
  let item = null;

  let theType = 'daily';
  let bottomRightUsage = 'monthly';
  if (activeChart === 'daily') {
    theType = 'weekly';
  } else if (activeChart === 'monthly') {
    theType = 'annually';
    bottomRightUsage = 'annually';
  }
  const month = moment().format('MMMM');
  let actualUsage = usage?.actual ? (usage?.actual[theType] as number) : 0;
  let annualUsage = usage?.baseline[bottomRightUsage]?.usage ? (usage?.baseline[bottomRightUsage].usage as number) : 0;
  let units = usage?.unit ? <>{usage.unit.replace('Gas ', '')}</> : <>units</>;
  if (type === 'water' && usage?.unit === 'Water m3') {
    units = (
      <>
        m<sup>3</sup>
      </>
    );
  } else if (usage?.unit === 'kWh' && actualUsage >= 1000000) {
    units = <>gWh</>;
  }
  const annualUnits = annualUsage >= 1000000 ? <>gWh</> : units;
  if (actualUsage >= 1000000) {
    actualUsage = Number((actualUsage / 1000000).toFixed(1));
  }
  if (annualUsage >= 1000000) {
    annualUsage = Number((annualUsage / 1000000).toFixed(1));
  }
  let targetUsage = 0; //  usage?.baseline ? (usage?.baseline[theType].total as number) : 0;
  let targetTotal = 0; //  usage?.baseline ? (usage?.baseline[theType].total as number) : 0;
  if (activeChart === 'monthly') {
    targetUsage = usage?.baseline?.annually?.usage || 0;
    targetTotal = usage?.baseline?.annually?.total || 0;
  } else {
    targetUsage = usage?.baseline?.monthly?.usage || 0;
    targetTotal = usage?.baseline?.monthly?.total || 0;
  }
  let percentage = targetUsage === 0 || targetTotal === 0 ? 0 : Math.round((targetUsage / targetTotal) * 100);
  let fillPercentage = percentage > 100 ? 100 : percentage;
  let stripesPercentage = percentage > 100 ? percentage - 100 : 0;
  if (stripesPercentage > 0) {
    fillPercentage = fillPercentage - stripesPercentage;
    stripesPercentage = stripesPercentage + 1;
  }
  if (stripesPercentage > 100) {
    stripesPercentage = 100 + 2;
  }

  React.useEffect(() => {
    let item = null;
    let opts = { type: 'delayed', duration: 800, selfDestroy: true };
    if (type === 'gas') {
      opts = { type: 'sync', duration: 1200, selfDestroy: true };
    }
    setTimeout(() => {
      // @ts-ignore
      item = new Vivus(`${type}`, opts);
    }, 0);
    return () => {
      if (item) {
        item.destroy();
      }
    };
  }, [activeChart]);

  return (
    <div className={`tile ${styles.usageTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      <Fade>
        <div className={styles.topRightContent}>
          <div className={styles.valueText}>
            {actualUsage !== null ? actualUsage.toLocaleString() : ''}
            {actualUsage !== null ? <span className={styles.units}>{units}</span> : null}
          </div>
          <div className={styles.date}>Consumed</div>
        </div>
        <div className={styles.otherContent}>
          <div className={`${styles.usageIcon}`}>
            {type === 'electricity' && <ElectricityIcon />}
            {type === 'gas' && <GasIcon />}
            {type === 'water' && <WaterIcon />}
          </div>
          <div className={styles.textContent}>
            <div className={`large-text ${styles.largeText}`}>{type} Usage</div>
            <label className={`small-text ${styles.smallText}`}>
              {activeChart === 'monthly'
                ? 'Last 12 months'
                : activeChart === 'hourly' || activeChart === 'categories'
                ? 'Last 24 hours'
                : 'Last 7 days'}
            </label>
          </div>
        </div>
      </Fade>
    </div>
  );
};
