import moment from 'moment';
import {
  ServicesDto,
  UsageDto,
  MetricsDto,
  CategoryDto,
  DataSetDto,
  WeatherDto,
  WeatherDataDto,
  DirectionDto,
  TemperatureDto,
  BreakdownDto,
} from './interfaces';

export const getMockData = () => {
  const showWeather = true;
  const data = {
    id: '53f00028-6d8d-4f31-8365-feac08877464',
    label: 'Albany Stadium Pool',
    theme: {
      backgroundImage: 'https://media.test-hub.esphq.com/FoyerDashboard/53f00028-6d8d-4f31-8365-feac08877464.jpg',
      background: [0, 0, 0],
      electricity: [197, 154, 110],
      water: [143, 192, 200],
      gas: [94, 167, 224],
      weather: [57, 181, 74],
    },
    utilities: [{ type: 'Electricity' }, { type: 'Gas' }, { type: 'Water' }],
    content: [
      {
        heading: 'Improved Ecological Value',
        body: 'The site was a previously undeveloped commercial section predominately covered with exotic kikuyu grass, yarrow and scattered gorse wildings. The new development has significantly improved the ecological value of the site with extensive planting including a mixture of native and exotic shrubs, ground covers and trees.',
        points: [
          'A new native fernland creating a diversified habitat for fauna and flora.',
          'A new native shrubbery & tree garden covering 19.16% of the site containing 2,500 native plants.',
          'A new rain garden to collect rainwater from the open carpark.',
        ],
        color: [144, 164, 79],
        metrics: [
          {
            heading: '643m3',
            text: 'maintained garden environment, combining native and exotic planting.',
            icon: 'leaf-icon',
            value: 0,
            tiled: false,
          },
          { heading: '73m3', text: 'of native fernland', icon: 'fern-icon', value: 0, tiled: false },
          { heading: '1506m2', text: 'if native shrubland', icon: 'shrub-icon', value: 0, tiled: false },
        ],
      },
      {
        heading: 'Design Features',
        body: '',
        points: [
          'A building utility dashboard to encourage efficient utility use through public awareness',
          'Increased fresh air rates (50% above NZBC)',
          'Extensive energy and water metering to allow consumption to be closely monitored and managed.',
          'Building materials selected based on recognised ecolabels',
          'Low emitting materials used in the construction',
          'Sustainable sourced (FSC) timber',
          'Reduced its water consumption through low flow water.',
          'Fixtures and rainwater harvesting serving toilets.',
        ],
        color: [149, 89, 117],
        metrics: [
          { heading: '16', text: 'Electric charging carparks', icon: 'ev-icon', value: 16, tiled: true },
          { heading: '8', text: 'Visitor bike racks', icon: 'bike-icon', value: 8, tiled: true },
          { heading: '48', text: 'End of Trip bike racks', icon: 'bike-icon', value: 48, tiled: true },
          { heading: '48', text: 'End of Trip lockers', icon: 'locker-icon', value: 48, tiled: true },
          { heading: '6', text: 'End of Trip showers', icon: 'shower-icon', value: 6, tiled: true },
          { heading: '8', text: 'Car pool carparks', icon: 'car-icon', value: 8, tiled: true },
        ],
      },
    ],
    showWeather: showWeather,
    result: 0,
  } as ServicesDto;

  if (window && window.location.search.indexOf('show3') > -1) {
    data.utilities.splice(showWeather ? 2 : 3);
  } else if (window && window.location.search.indexOf('show2') > -1) {
    data.utilities.splice(showWeather ? 1 : 2);
  } else if (window && window.location.search.indexOf('show1') > -1) {
    data.utilities.splice(1);
    data.showWeather = false;
  }
  return data;
};

export const getMockUsage = (utilType: string) => {
  const unit =
    utilType === 'Electricity' ? 'kwh' : utilType === 'Gas' ? 'units' : utilType === 'Water' ? 'litres' : 'units';
  const equivTo =
    utilType === 'Electricity' ? 'Homes' : utilType === 'Gas' ? 'Bottles' : utilType === 'Water' ? 'Bathtubs' : 'Units';

  const targetDaily = Math.round(Math.random() * 200) + 100;
  const actualDaily = Math.round(Math.random() * 200) + 100;
  const equivDaily = Math.round(Math.random() * 5000) + 1;
  const usage = {
    actual: {
      daily: actualDaily,
      weekly: actualDaily * 7,
      monthly: actualDaily * 30,
      annually: actualDaily * 356,
    },
    baseline: {
      daily: {
        total: targetDaily,
        usage: targetDaily,
      },
      weekly: {
        total: targetDaily * 7,
        usage: targetDaily * 7,
      },
      monthly: {
        total: targetDaily * 30,
        usage: targetDaily * 30,
      },
      annually: {
        total: targetDaily * 365,
        usage: targetDaily * 365,
      },
    },
    unit: unit,
    equivalentTo: {
      uom: equivTo,
      value: { daily: equivDaily, weekly: equivDaily * 7, monthly: equivDaily * 30, annually: equivDaily * 356 },
    },
  } as UsageDto;
  return usage;
};

export const getMockMetrics = (utilType: string) => {
  const output = {
    // monthly: { targets: [], actuals: [], result: 0 },
    // daily: { targets: [], actuals: [], result: 0 },
    // hourly: { targets: [], actuals: [], result: 0 },
    monthly: { actuals: [], result: 0 } as DataSetDto,
    daily: { actuals: [], result: 0 } as DataSetDto,
    hourly: { actuals: [], result: 0 } as DataSetDto,
    categories: [],
    result: 0,
    type: utilType,
    usage: getMockUsage(utilType),
  } as MetricsDto;

  [...Array(24)].forEach((value: undefined, index: number) => {
    // const target = Math.random() * 1000 + 100;
    const actual = Math.round(Math.random() * 900 + 100);
    const date = moment().add(index, 'hour').toString();
    output.hourly.actuals.push({
      event: date,
      usage: actual,
    });
    // output.hourly.targets.push({
    //   event: date,
    //   usage: target,
    // });
  });
  [...Array(7)].forEach((value: undefined, index: number) => {
    // const target = Math.random() * 1000 + 100;
    const actual = Math.round(Math.random() * 900 + 100);
    const date = moment().add(index, 'day').toString();
    output.daily.actuals.push({
      event: date,
      usage: actual,
    });
    // output.daily.targets.push({
    //   event: date,
    //   usage: target,
    // });
  });

  [...Array(12)].forEach((value: undefined, index: number) => {
    // const target = Math.random() * 1000 + 100;
    const actual = Math.round(Math.random() * 900 + 100);
    const date = moment().add(index, 'month').toString();
    output.monthly.actuals.push({
      event: date,
      usage: actual,
    });
    // output.monthly.targets.push({
    //   event: date,
    //   usage: target,
    // });
  });

  const categories = {
    electricity: ['Building Mechanical Services', 'General Lighting and Power'],
    water: ['Swimming Pool', 'General Water'],
    gas: ['Heating', 'Cooking', 'Other'],
  };

  categories[utilType.toLowerCase()].forEach((cat: string, index: number) => {
    const newCat = { category: cat, total: 0, data: [] as BreakdownDto[] } as CategoryDto;
    [...Array(5)].forEach((cat: undefined, index: number) => {
      const actual = Math.round(Math.random() * 900 + 100);
      newCat.total += actual;
      newCat.data.push({ label: `Floor ${index}`, usage: actual });
    });
    output.categories.push(newCat);
  });

  return output;
};

export const generateRandomTemperature = () => {
  const temperature = {
    min: Math.random() * 30 - 20,
    max: Math.random() * 10 + 30,
  } as TemperatureDto;
  return temperature;
};

export const generateWeatherEvent = (date: string) => {
  const directions = ['N', 'E', 'S', 'SW'];
  const conditions = ['cloudy', 'sunny', 'storm', 'rain'];
  const dirMin = Math.round(Math.random() * directions.length);
  const dirMax = Math.round(Math.random() * directions.length);
  const conditionIdx = Math.round(Math.random() * conditions.length);
  const current = {
    date: date,
    temp: generateRandomTemperature(),
    feelsLike: generateRandomTemperature(),
    humidity: generateRandomTemperature(),
    windSpeed: generateRandomTemperature(),
    direction: {
      min: directions[dirMin],
      max: directions[dirMax],
    } as DirectionDto,
    conditions: 'Mostly ' + conditions[conditionIdx] + ' with a change coming soon ',
  } as WeatherDataDto;
  return current;
};

export const getMockWeather = () => {
  const data = {
    city: 'Auckland',
    countryCode: 'NZ',
    events: [],
    result: 0,
  } as WeatherDto;

  for (var i = 0; i < 6; i++) {
    data.events.push(generateWeatherEvent(moment().add(i, 'day').toString()));
  }
  return data;
};

export default getMockData;
