import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import { WeatherDto, WeatherDataDto } from 'utilities';
import { ReactComponent as WindmillIcon } from 'assets/windmill.svg';
import styles from './wind-tile.module.scss';

interface WindTileProps {
  color: string;
  weather: WeatherDto;
}

const average = (num: number, num2: number) => {
  if (num === undefined || num === null || num2 === undefined || num2 === null) {
    return '-';
  }
  return Math.round((num + num2) / 2);
};

const expandDirection = (str: string) => {
  const arr = {
    N: 'North',
    E: 'East',
    W: 'West',
    S: 'South',
  };
  let output = [];
  for (var i = 0; i < str.length; i++) {
    output.push(arr[str.charAt(i)]);
  }
  return output.join(' ');
};

export const WindTile = ({ color, weather }: WindTileProps) => {
  const currentWeather = weather?.events.length > 0 ? weather.events[0] : ({} as WeatherDataDto);
  const windSpeed = average(currentWeather.windSpeed.min, currentWeather.windSpeed.max);
  // just to test normal commit
  return (
    <div className={`tile  ${styles.windTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      <Fade>
        <div className={`large-text ${styles.largeText}`}>
          {windSpeed}
          <span>KM/hr</span>
        </div>
        <div className={styles.windContainer}>
          <WindmillIcon />
          <WindmillIcon />
          <WindmillIcon />
        </div>
        {currentWeather && currentWeather.direction ? (
          <h3 className="h2">Wind Direction: {expandDirection(currentWeather.direction.min || '')}</h3>
        ) : null}
      </Fade>
    </div>
  );
};
