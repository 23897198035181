import * as React from 'react';
import moment from 'moment';
import { Fade } from 'react-awesome-reveal';
import { WeatherDataDto, WeatherDto } from 'utilities';
import { WeatherIcon } from 'components';
import styles from './forecast-tile.module.scss';

interface ForecastTileProps {
  color: string;
  weather: WeatherDto;
}

const average = (num: number, num2: number) => {
  if (num === undefined || num === null || num2 === undefined || num2 === null) {
    return '-';
  }
  return Math.round((num + num2) / 2);
};

export const ForecastTile = ({ color, weather }: ForecastTileProps) => {
  const currentWeather = weather?.events.length > 0 ? weather.events[0] : ({} as WeatherDataDto);

  return (
    <div className={`tile ${styles.forecastTile}`}>
      <Fade>
        <div className={`${styles.location}`} style={{ color: `rgb(${color})` }}>
          {weather.city} <span>weather</span>
        </div>
        <div className={styles.currentWeather} style={{ color: `rgb(${color})` }}>
          <div className={styles.tempWrapper}>
            <div className={styles.largeTemperature}>
              {average(currentWeather.temp.min, currentWeather.temp.max)}
              <sup>&#x2103;</sup>
            </div>
            <div
              className={`${styles.currentWeatherDescription} currentWeatherDescription`}
              style={{ color: `rgb(${color})` }}>
              {currentWeather.conditions}
            </div>
          </div>
          <div className={`${styles.weatherIcon} weatherIcon`}>
            <WeatherIcon description={currentWeather.conditions} color={color} />
          </div>
        </div>

        <div className={styles.forecasts}>
          {weather.events.map((evt, index) => {
            const dt = moment(evt.date).format('ddd');
            if (index === 0) {
              return null;
            } else {
              return (
                <div key={index} className={styles.forecastItem}>
                  <div className={styles.forecastItemIcon}>
                    <WeatherIcon description={evt.conditions} />
                  </div>
                  <div className={styles.forecastItemTemperature}>
                    {average(evt.temp.min, evt.temp.max)}
                    <sup>&#x2103;</sup>
                  </div>
                  <div className={styles.forecastItemDate}>{dt}</div>
                </div>
              );
            }
          })}
        </div>
      </Fade>
    </div>
  );
};
