import React from 'react';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AppContext, DataSetDto } from 'utilities';
import getChartOptions from './chartOptions';

interface LineChartProps {
  utilType: string;
  utilTheme: string;
}

export const LineChartComponent = ({ utilType, utilTheme }: LineChartProps) => {
  const { metrics, activeChart } = React.useContext(AppContext);
  const metric = metrics?.length > 0 ? metrics.find((x) => x.type === utilType?.toLowerCase()) : null;
  const consumption = metric[activeChart] as DataSetDto;
  const unit = metric?.usage?.unit ? metric.usage.unit.replace('Water ', '').replace('Gas ', '') : 'units';
  const data = [];
  let hasNull = false;
  let max = 0;
  for (let i = 0; i < consumption.actuals.length; i++) {
    const actual = consumption.actuals[i];
    let act = Math.ceil(actual?.usage) || (utilType === 'Electricity' ? null : 0);
    if (actual?.usage > 0 && actual?.usage < 1) {
      act = Number(actual?.usage.toFixed(2));
    }
    if (act === null) {
      hasNull = true;
    }
    data.push({ x: Number(moment(actual.event).format('x')), y: act });
    if (act > max) {
      max = act;
    }
  }
  if (max === 0) {
    max = 1;
  }

  const series = [
    {
      data: utilType === 'Electricity' && hasNull ? data : [],
      color: '#ccc',
      connectNulls: true,
      dashStyle: 'dash',
    },
    {
      data: data,
    },
  ];
  const options = getChartOptions(utilTheme, unit, max, 'line');
  options.series = series;

  // @ts-ignore
  // options.xAxis.labels.autoRotation = false;
  // @ts-ignore
  options.xAxis.labels.step = window.innerWidth > 1920 ? 3 : null;
  options.chart.marginBottom = window.innerWidth > 1920 ? 80 : 60;
  // options.xAxis.labels.style.fontSize = '16px';
  // options.xAxis.labels.style.fontWeight = 'bold';
  // @ts-ignore
  options.xAxis.labels.formatter = (point) => {
    return moment(point.value).format('HH:mm');
  };

  React.useEffect(() => {
    Highcharts.charts.forEach((x) => {
      if (x) {
        x.reflow();
      }
    });
  });

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        allowChartUpdate={true}
        immutable={true}
        options={options}
      />
    </div>
  );
};
