import React from 'react';

import styles from './weather-icons.module.scss';

interface WeatherIconProps {
  description: string;
  color?: string;
}

export const WeatherIcon = ({ description, color }: WeatherIconProps) => {
  // const obj = {
  //   sunny: '(sunny|clear|fair)',
  //   clearn: '(sunny|clear|fair)n',
  //   hazy: '(hazy|smoke|dust)',
  //   hazyn: '(hazy|smoke|dust)n',
  //   drizzle: 'drizzlef?',
  //   tstorms: 'tstorms?w?',
  //   fog: 'fogn?',
  //   wind: '(wind|pcloudywn?|mcloudywn?)',
  //   snowflurries: '(snow)?flurries',
  //   sleet: '(sleet|freezingrain)',
  //   rainandsnow: '(rain(and)?snow|rainsnow)',
  //   showers: 'showersw?n?',
  // };
  const d = description?.toLowerCase() || '';
  const snowKeys = ['snow', 'ice', 'frost', 'sleet', 'freezing', 'flurries'];
  const stormKeys = ['storm', 'thunder', 'lightning'];
  const rainKeys = ['drizzle', 'rain', 'hail', 'shower'];
  const windKeys = ['wind', 'cloud', 'fog', 'hazy', 'smoke', 'dust'];
  const cssProps = {};
  if (color) {
    cssProps['--main-color'] = `rgb(${color})`;
  }

  if (snowKeys.find((x) => d.indexOf(x) > -1)) {
    return <Snowy cssProps={cssProps} />;
  } else if (stormKeys.find((x) => d.indexOf(x) > -1)) {
    return <Stormy cssProps={cssProps} />;
  } else if (rainKeys.find((x) => d.indexOf(x) > -1)) {
    return <Rainy cssProps={cssProps} />;
  } else if (windKeys.find((x) => d.indexOf(x) > -1)) {
    return <Cloudy cssProps={cssProps} />;
  } else {
    return <Sunny cssProps={cssProps} />;
  }
};

export const Stormy = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.thunderStorm}`} style={cssProps}>
    <div className={`${styles.cloud}`} />
    <div className={`${styles.lightning}`}>
      <div className={`${styles.bolt}`} />
      <div className={`${styles.bolt}`} />
    </div>
  </div>
);

export const Cloudy = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.cloudy}`} style={cssProps}>
    <div className={`${styles.cloud}`} />
    <div className={`${styles.cloud}`} />
  </div>
);

export const Foggy = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.cloudy}`} style={cssProps}>
    <div className={`${styles.cloud}`} />
    <div className={`${styles.cloud}`} />
    <div className={`${styles.fog}`} />
  </div>
);

export const Snowy = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.flurries}`} style={cssProps}>
    <div className={`${styles.cloud}`} />
    <div className={`${styles.snow}`}>
      <div className={`${styles.flake}`} />
      <div className={`${styles.flake}`} />
    </div>
  </div>
);

export const Sunny = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.sunny}`} style={cssProps}>
    <div className={`${styles.sun}`}>
      <div className={`${styles.rays}`} />
    </div>
  </div>
);

export const Rainy = ({ cssProps }) => (
  <div className={`${styles.icon} ${styles.rainy}`} style={cssProps}>
    <div className={`${styles.cloud}`} />
    <div className={`${styles.rain}`} />
  </div>
);
