import React from 'react';
import packageJson from '../../../package.json';
// @ts-ignore
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

interface CacheBusterProps {
  children: any;
}

interface CacheBusterState {
  loading: boolean;
  isLatestVersion: boolean;
  refreshCacheAndReload: () => void;
}

let intervalTimeout = null as any;
let reloadTimeout = null as any;
export class CacheBuster extends React.Component<CacheBusterProps, CacheBusterState> {
  constructor(props: CacheBusterProps) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }

        clearTimeout(reloadTimeout);
        reloadTimeout = setTimeout(() => {
          // sessionStorage.clear();
          // window.location.reload(true);
        }, 5000);
        // delete browser cache and hard reload
        // window.location.reload(true);
      },
    };
  }

  // start check
  componentDidMount() {
    this.check();
    clearInterval(intervalTimeout);
    intervalTimeout = setInterval(() => {
      console.log('checking cache');
      this.check();
    }, 1000 * 60);
  }

  check() {
    fetch('/meta.json?' + new Date().getTime())
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        // @ts-ignore
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;
