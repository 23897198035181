import * as React from 'react';

import { CustomPageDto } from 'utilities';
import { SlideWrapper } from 'components';

import styles from './content.module.scss';

interface CustomPageProps {
  data: CustomPageDto;
}

export const CustomPage = ({ data }: CustomPageProps) => {
  const isLandscape = window.innerWidth > window.innerHeight;
  if (isLandscape) {
    if (data.imageFirstL && data.imageSecondL) {
      return (
        <div className={`${styles.customPage} ${styles.landscapeMulti}`}>
          <div style={{ backgroundImage: `url(${data.imageFirstL})` }} />
          <div style={{ backgroundImage: `url(${data.imageSecondL})` }} />
          <div style={{ backgroundImage: `url(${data.imageThirdL})` }} />
          <div style={{ backgroundImage: `url(${data.imageFourthL})` }} />
        </div>
      );
    } else {
      return (
        <div className={`${styles.customPage} ${styles.landscapeSingle}`}>
          <div style={{ backgroundImage: `url(${data.imageFirstL})` }} />
        </div>
      );
    }
  } else {
    if (data.imageFirstP && data.imageSecondP) {
      return (
        <div className={`${styles.customPage} ${styles.portraitMulti}`}>
          <div style={{ backgroundImage: `url(${data.imageFirstP})` }} />
          <div style={{ backgroundImage: `url(${data.imageSecondP})` }} />
          <div style={{ backgroundImage: `url(${data.imageThirdP})` }} />
        </div>
      );
    } else {
      return (
        <div className={`${styles.customPage} ${styles.portraitSingle}`}>
          <div style={{ backgroundImage: `url(${data.imageFirstP})` }} />
        </div>
      );
    }
  }
};

export const ContentPage = ({ data }: CustomPageProps) => {
  return (
    <SlideWrapper>
      <CustomPage data={data} />
    </SlideWrapper>
  );
};
