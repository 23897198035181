import * as React from 'react';

import styles from './mask.module.scss';

export interface MaskProps {
  backgroundColor?: any;
  backgroundImageL?: any;
  backgroundImageP?: any;
}

export const Mask = ({ backgroundColor, backgroundImageL, backgroundImageP }: MaskProps) => {
  if (!backgroundColor) {
    return <div />;
  }

  let defaultBackgroundImage = backgroundImageL ? backgroundImageL : backgroundImageP;
  if (window.innerWidth < window.innerHeight && backgroundImageP) {
    defaultBackgroundImage = backgroundImageP;
  }
  return (
    <div className={styles.mask} style={{ backgroundColor: 'rgb(' + backgroundColor.join() + ')' }}>
      {defaultBackgroundImage ? (
        <div
          className={styles.maskImage}
          style={{ background: 'url(' + defaultBackgroundImage + ') center center no-repeat' }}></div>
      ) : (
        <div />
      )}
    </div>
  );
};
