import * as React from 'react';
import { Loader } from 'components';

import styles from './loading-tile.module.scss';

interface LoadingTileProps {
  color: string;
  copy?: string;
}

export const LoadingTile = ({ color, copy }: LoadingTileProps) => {
  return (
    <div className={`tile loading-tile ${styles.loadingTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      {copy ? <h3>{copy}</h3> : <Loader />}
    </div>
  );
};
