import * as React from 'react';
import styles from './box.module.scss';

interface BoxProps {
  color: string;
  children: any;
}

export const Box = ({ color, children }: BoxProps) => {
  return (
    <div className={`box ${styles.box}`}>
      <div className={`box-content ${styles.boxContent}`}>{children}</div>
    </div>
  );
};
