import * as React from 'react';
import axios from 'axios';
import moment from 'moment';
import { getMockData, getMockMetrics, getMockUsage, getMockWeather } from './mockData';
import {
  CategoriesDto,
  CategoryDto,
  Co2Dto,
  CombinedDto,
  ContentDto,
  DashboardDetailDto,
  DataSetDto,
  MetricsDto,
  UsageDto,
  WeatherDto,
} from './interfaces';
import { errorHandler, requestHandler, responseHandler } from './cache';

interface AppProviderProps {
  children: any;
}

interface AppProviderState extends CombinedDto {
  running: boolean;
  loading: boolean;
  loadingUsages: boolean;
  loadingConsumptions: boolean;
  loadingCo2: boolean;
  loadingWeather: boolean;
  apiError: boolean;
  notFound: boolean;
  activeChart: string;
  activePage: number;
  resizing: boolean;
  mockEnabled?: boolean;
  appKey: string;
  utilities: string[];
}

const API_ENV = process.env.REACT_APP_API_ENV || '';
const api = axios.create({
  baseURL: `${API_ENV}/analytics/view`,
});

// CACHE only on test enviroment
if (API_ENV.indexOf('test') > -1) {
  api.interceptors.request.use((request) => requestHandler(request));
  api.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
}

export const defaultState: AppProviderState = {
  running: false,
  loading: true,
  loadingUsages: true,
  loadingConsumptions: true,
  loadingCo2: true,
  loadingWeather: true,
  apiError: false,
  notFound: false,
  activeChart: 'hourly',
  activePage: 0,
  resizing: false,
  metrics: [],
  appKey: '',
  utilities: [],
  // mockEnabled: false,
  // showWeather: false,
};

export interface AppContextProps extends AppProviderState {
  updateState: (data: AppProviderState) => void;
}

export const AppContext = React.createContext<Partial<AppContextProps>>(defaultState);

export class AppProvider extends React.Component<AppProviderProps, AppProviderState> {
  public chartTypes = ['hourly', 'categories', 'daily', 'monthly'];
  public transitionChartsTime = 1000 * 10; // 10 seconds
  public transitionPageTime = this.transitionChartsTime * this.chartTypes.length; // 1 minute
  public requestDataTime = 1000 * 60 * 60; // 1 hour
  public restartTime = 1000 * 60 * 2; // 2 minutes
  public requestWeatherTime = 1000 * 60 * 60 * 24; // 24 hours
  public state: AppProviderState = { ...defaultState };
  public runTimer = null as any;
  public chartTimer = null as any;
  public pageTimer = null as any;
  public weatherTimer = null as any;
  public resizeTimeout = null as any;

  public async componentDidMount() {
    const appKey = window && window.location ? window.location.pathname.replace('/', '') : '';
    const mockEnabled = window && window.location ? window.location.search.indexOf('show') > -1 : false;

    if (appKey?.length !== 6) {
      if (window.location.href.indexOf('dashboard-test') > -1) {
        window.location.href = `https://test-hub.esphq.com`;
        return;
      } else if (window.location.href.indexOf('dashboard.esphq') > -1) {
        window.location.href = `https://hub.esphq.com`;
        return;
      }

      this.setState({ notFound: true });
      const tag = {
        page_title: document.title + ' | Not Found',
        page_location: window.location.href,
        page_path: window.location.pathname,
      };
      // @ts-ignore
      window.gtag('config', process.env.REACT_APP_GTAG, tag);
    } else {
      this.setState({ mockEnabled });
      setTimeout(() => {
        this.run(appKey);
      }, 0);
    }

    window.addEventListener('resize', () => {
      this.setState({ resizing: true });
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.setState({ resizing: false });
      }, 1000);
    });
  }

  public render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          updateState: this.updateState,
        }}>
        {children}
      </AppContext.Provider>
    );
  }

  /**
   * State machine
   * @param appKey
   */
  public async run(appKey: string) {
    // prevent multiple instances of run
    if (this.state.running) {
      return;
    }

    this.setState({ ...defaultState, running: true, appKey });

    // load data
    const analyticsResult = await this.requestAnalytics(appKey);

    // go to error
    if (!analyticsResult) {
      this.setState({ running: false, apiError: true });
      this.restart(appKey);
      return;
    }

    const tag = {
      page_title: document.title + ' | ' + appKey + ' | ' + this.state.dashboardName,
      page_location: window.location.href,
      page_path: window.location.pathname,
    };
    // @ts-ignore
    window.gtag('config', process.env.REACT_APP_GTAG, tag);

    const promises = [this.requestUsages(appKey), this.requestUtilitiesConsumptions(appKey), this.requestCo2(appKey)];
    if (this.state.showWeather) {
      promises.push(this.requestWeather(appKey));
    }
    // request core data
    await Promise.all(promises);

    // go to error
    if (this.state.metrics.length === 0) {
      this.setState({ running: false, apiError: true });
      this.restart(appKey);
      return;
    }
    // request data again
    this.runRunTimer(appKey);
    // transition charts
    this.runChartTimer();
    // transition page
    this.runPageTimer();

    // clear running, prevent bulk icons being loaded until atleast this happens
    setTimeout(() => {
      this.setState({ running: false });
      // @ts-ignore
      window.fbUpdateUserDate();
    }, 250);

    // force hard reload
    const timeToMidnight = moment(new Date()).endOf('day').add(1, 'second').add(1, 'minute').diff(moment(new Date()));
    console.log('force reload at ', timeToMidnight, moment().add(timeToMidnight).format());
    setTimeout(() => {
      sessionStorage.clear();
      window.location.reload(true);
    }, timeToMidnight);
  }

  /**
   * On API error try again
   * @param appKey
   */
  public async restart(appKey: string) {
    clearTimeout(this.runTimer);
    clearTimeout(this.chartTimer);
    clearTimeout(this.pageTimer);
    clearTimeout(this.weatherTimer);
    setTimeout(() => {
      this.setState({ apiError: false, loading: true });
      this.run(appKey);
    }, this.restartTime);
  }

  /**
   * Runs run after requestDataTime
   */
  public runRunTimer(appKey: string) {
    clearTimeout(this.runTimer);
    this.runTimer = setTimeout(async () => {
      this.run(appKey);
    }, this.requestDataTime);
  }

  /**
   * Transitions page after transitionPageTime
   */
  public runPageTimer() {
    clearTimeout(this.pageTimer);
    const time = this.state.activePage === 0 ? this.transitionPageTime : this.transitionChartsTime * 2;
    this.pageTimer = setTimeout(() => {
      this.transitionPage();
    }, time);
  }

  /**
   * Transition charts after transitionChartsTime
   */
  public runChartTimer() {
    clearTimeout(this.chartTimer);
    this.chartTimer = setTimeout(() => {
      this.transitionCharts();
    }, this.transitionChartsTime);
  }

  /**
   * Runs weather data request after 24 hours
   */
  public runWeatherTimer() {
    clearTimeout(this.weatherTimer);
    this.weatherTimer = setTimeout(() => {
      this.requestWeather(this.weatherTimer);
    }, this.requestWeatherTime);
  }

  /**
   * Method available off app context to update internal state
   * @param data
   */
  private updateState = (data: AppProviderState) => {
    this.setState({ ...data });
  };

  /**
   * Calls Analaytics Dashboard API to get utilities and theme data
   * @param appKey
   */
  private requestAnalytics = async (appKey: string) => {
    const url = `${appKey}/dashboard`;

    this.setState({ loading: true });

    try {
      const { data } = await api.get<DashboardDetailDto>(url);
      const utilities = [];
      if (data.showElectricity) {
        utilities.push('electricity');
      }
      if (data.showWater) {
        utilities.push('water');
      }

      if (data.showGas) {
        utilities.push('gas');
      }

      this.setState({
        loading: false,
        utilities,
        ...data,
      });

      return true;
    } catch (ex) {
      return false;
    }
  };

  /**
   * Calls Usage API for each utility to get usage data
   * @param appKey
   */
  private requestUsages = async (appKey: string) => {
    this.setState({ loadingUsages: true });
    const promises = this.state.utilities.map((util) => {
      return this.requestUsage(appKey, util);
    });
    const results = await Promise.all(promises);
    this.setState({ loadingUsages: false });
    return results.indexOf(false) > -1;
  };

  /**
   * Calls Usage API for a specific utility
   * @param appKey
   */
  private requestUsage = async (appKey: string, utilType: string) => {
    const url = `${appKey}/${utilType}`;
    try {
      const { data } = await api.get<UsageDto>(url);
      this.updateOrAddUsageToMetricByUtilType(utilType, data);
      return true;
    } catch (ex) {
      // todo: decide what to do on error
      return false;
    }
  };

  /**
   * Calls requestMetrics API for each utility to get consumptoin data
   * @param appKey
   */
  private requestUtilitiesConsumptions = async (appKey: string) => {
    this.setState({ loadingConsumptions: true });
    const promises = this.state.utilities.map((util) => {
      return this.chartTypes.map((chartType) => {
        return this.requestUtilityConsumption(appKey, util, chartType.toLowerCase());
      });
    });
    const results = await Promise.all(promises.flat());
    this.setState({ loadingConsumptions: false });
    return results.indexOf(false) > -1;
  };

  /**
   * Calls Usage API for a specific utility
   * @param appKey
   */
  private requestUtilityConsumption = async (appKey: string, utilType: string, chartType: string) => {
    const url = `${appKey}/${utilType}/${chartType}`;
    try {
      if (this.state.mockEnabled) {
        await new Promise(async (innerResolve) => {
          setTimeout(() => {
            innerResolve(true);
          }, Math.random() * 5000 + 1000);
        });
        this.updateOrAddChartDataToMetricByUtilType(utilType, chartType, getMockMetrics(utilType)[chartType]);
        return true;
      } else {
        const { data } = await api.get<DataSetDto | CategoriesDto>(url);
        if (chartType === 'categories') {
          this.updateOrAddChartDataToMetricByUtilType(utilType, chartType, (data as CategoriesDto).loads);
        } else {
          this.updateOrAddChartDataToMetricByUtilType(utilType, chartType, data as DataSetDto);
        }
        // force chart to show once this is done atleast
        if (chartType === 'hourly') {
          this.setState({ loadingConsumptions: false });
        }
        return true;
      }
    } catch (ex) {
      // todo: decide what to do on error
      return false;
    }
  };

  /**
   * Calls Analaytics Dashboard API to get co2 data
   * @param appKey
   */
  private requestCo2 = async (appKey: string) => {
    const url = `${appKey}/co2`;
    this.setState({ loadingCo2: true });
    if (this.state.mockEnabled) {
      await new Promise(async (resolve) => {
        setTimeout(() => {
          resolve(true);
        }, Math.random() * 5000 + 1000);
      });
      const co2 = this.getEmptyCo2Data();
      co2.trees.daily = 999;
      this.setState({ co2, loadingCo2: false });
      return true;
    } else {
      try {
        const { data } = await api.get<Co2Dto>(url);
        this.setState({ co2: data });
        return true;
      } catch (ex) {
        return false;
      } finally {
        this.setState({ loadingCo2: false });
      }
    }
  };

  /**
   * Calls Analaytics Dashboard API to get weather data
   * @param appKey
   */
  private requestWeather = async (appKey: string) => {
    const url = `${appKey}/weather`;
    this.setState({ loadingWeather: true });
    if (this.state.mockEnabled) {
      await new Promise(async (resolve) => {
        setTimeout(() => {
          resolve(true);
        }, Math.random() * 5000 + 1000);
      });
      const weather = getMockWeather();
      this.setState({ weather, loadingWeather: false });
      return true;
    } else {
      try {
        const { data } = await api.get<WeatherDto>(url);
        this.setState({ weather: data });
        return true;
      } catch (ex) {
        return false;
      } finally {
        this.setState({ loadingWeather: false });
      }
    }
  };

  /**
   * Transitions the page
   */
  public transitionPage() {
    let availablePagesCount = this.state.customPages?.length + 1;
    let idx = this.state.activePage;
    if (idx + 1 >= availablePagesCount) {
      idx = 0;
    } else {
      idx++;
    }
    if (idx === 0) {
      this.setState({ activeChart: 'hourly' });
      this.runChartTimer();
    }
    setTimeout(() => {
      this.setState({ activePage: idx });
      this.runPageTimer();
    }, 0);
  }

  /**
   * Transitions the chart between hourly, daily, monthly
   */
  public transitionCharts() {
    let idx = this.chartTypes.findIndex((x) => x === this.state.activeChart);
    if (idx + 1 >= this.chartTypes.length) {
      idx = 0;
    } else {
      idx++;
    }
    this.setState({ activeChart: this.chartTypes[idx] });
    this.runChartTimer();
  }

  /**
   * Update or add usage data to a metric by utilType
   * @param utilType
   * @param usage
   */
  private updateOrAddUsageToMetricByUtilType = (utilType: string, usage: UsageDto) => {
    let metric = this.getEmptyMetricData(utilType);
    const existingState = { ...this.state };
    const existingIdx = existingState.metrics.findIndex((x) => x.type === utilType);
    if (existingIdx > -1) {
      existingState.metrics[existingIdx].usage = usage;
    } else {
      metric.usage = usage;
      existingState.metrics.push(metric);
    }
    this.setState({ metrics: existingState.metrics });
  };

  /**
   * Update or add chart data to a metric by utilType
   * @param utilType
   * @param usage
   */
  private updateOrAddChartDataToMetricByUtilType = (
    utilType: string,
    chartType: string,
    data: DataSetDto | CategoryDto[]
  ) => {
    let metric = this.getEmptyMetricData(utilType);
    const existingState = { ...this.state };
    const existingIdx = existingState.metrics.findIndex((x) => x.type === utilType);
    if (existingIdx > -1) {
      existingState.metrics[existingIdx][chartType] = data;
    } else {
      metric[chartType] = data;
      existingState.metrics.push(metric);
    }
    this.setState({ metrics: existingState.metrics });
  };

  /**
   * Utility method for testing/mockdata
   * @param utilType
   */
  private getEmptyMetricData = (utilType: string) => {
    return {
      type: utilType,
      annually: { targets: [], actuals: [], result: 0 },
      monthly: { targets: [], actuals: [], result: 0 },
      daily: { targets: [], actuals: [], result: 0 },
      hourly: { targets: [], actuals: [], result: 0 },
      categories: [],
      result: 0,
      usage: this.getEmptyUsageData(utilType),
    } as MetricsDto;
  };

  /** Utilty method to get Empty Usage data */
  private getEmptyUsageData = (utilType: string) => {
    return {
      type: utilType,
      actual: {
        daily: 0,
        weekly: 0,
        monthly: 0,
        annually: 0,
      },
      equivalentTo: {
        uom: '',
        value: {
          daily: 0,
          weekly: 0,
          monthly: 0,
          annually: 0,
        },
      },
      baseline: {
        monthly: {
          total: 0,
          usage: 0,
        },
        annually: {
          total: 0,
          usage: 0,
        },
      },
      unit: null,
    } as UsageDto;
  };

  /**
   * Get empty co2 data
   */
  private getEmptyCo2Data = () => {
    return {
      meta: { age: 0, ratio: 0 },
      totals: { kwh: 0, tonne: 0, kg: 0 },
      trees: { daily: 0, yearly: 0 },
      result: 0,
    } as Co2Dto;
  };

  /**
   * Get empty weather data
   */
  private getEmptyWeatherData = () => {
    return {
      city: '',
      countryCode: '',
      events: [],
      result: 0,
    } as WeatherDto;
  };

  private toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
}

export const AppConsumer = AppContext.Consumer;

export default AppProvider;
