import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import moment from 'moment';
import getChartOptions from './chartOptions';

import { AppContext, CategoryDto, getTextWidth } from 'utilities';

interface HorizontalBarChartProps {
  utilType: string;
  utilTheme: string;
}

export const HorizontalBarChart = ({ utilType, utilTheme }: HorizontalBarChartProps) => {
  const { metrics, activeChart } = React.useContext(AppContext);
  const metric = metrics?.length > 0 ? metrics.find((x) => x.type === utilType?.toLowerCase()) : null;
  const consumption = metric[activeChart] as CategoryDto[];
  const unit = metric?.usage?.unit ? metric.usage.unit.replace('Water ', '').replace('Gas ', '') : 'units';

  const categories = [];
  let data = [];

  consumption.forEach((cons) => {
    if (categories.indexOf(cons.category) === -1) {
      categories.push(cons.category);
    }
    cons.data.forEach((consItem, i) => {
      let item = data.find((x) => x.name === consItem.label);
      if (!item) {
        data.push({ name: consItem.label, data: [consItem.usage], color: `rgb(${utilTheme}` });
      } else {
        item.data.push(consItem.usage);
        item.color = `${shadeColor(utilTheme, 80 - (i + 1) * 10)}`;
      }
    });
  });

  const series = data;
  const options = getChartOptions(utilTheme, unit, null, 'horizontal');
  options.series = series;
  // @ts-ignore
  options.legend.enabled = true;
  options.chart.type = 'bar';
  // options.chart.marginBottom = 80;
  // @ts-ignore
  options.xAxis.categories = categories;
  options.xAxis.tickInterval = 1;
  // options.xAxis.labels.style.fontSize = '14px';
  options.xAxis.type = null;
  // @ts-ignore
  // options.yAxis.labels.autoRotation = false;
  // @ts-ignore
  options.yAxis.labels.step = 4;
  // @ts-ignore
  options.yAxis.labels.formatter = function () {
    if (this.value === 0) {
      return '';
    } else {
      return this.axis.defaultLabelFormatter.call(this);
    }
  };
  // @ts-ignore
  options.xAxis.tickmarkPlacement = 'on';
  // options.xAxis

  React.useEffect(() => {
    Highcharts.charts.forEach((x) => {
      if (x) {
        x.reflow();
      }
    });
  });

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        allowChartUpdate={true}
        immutable={true}
        options={options}
      />
    </div>
    // <ResponsiveContainer width="100%" height="100%" debounce={100}>
    //   <BarChart
    //     data={data}
    //     layout="vertical"
    //     margin={{
    //       left: 0,
    //       right: 30,
    //       top: 50,
    //     }}>
    //     <XAxis type="number" dataKey="usage" unit={` ${unit}`} tickLine={false} />
    //     <YAxis type="category" dataKey="category" width={yAxisWidth} dx={-5} />
    //     {/* <Bar dataKey={'usage'} fill={`rgb(${utilTheme})`} barSize={50} stackId={'a'} /> */}
    //     {/* <Bar dataKey={'2'} fill={`rgba(${utilTheme}, 0.4})`} barSize={50} stackId={'a'} /> */}
    //     {keys.map((key, i) => {
    //       let fill = `${shadeColor(utilTheme, 80 - (i + 1) * 10)}`;
    //       if (keys.length === 1) {
    //         fill = `rgb(${utilTheme})`;
    //       }
    //       return <Bar key={i} dataKey={i} name={key} fill={fill} barSize={50} stackId={'a'} />;
    //     })}

    //     {keys.length > 1 && (
    //       <Legend
    //         layout="vertical"
    //         align="right"
    //         verticalAlign="top"
    //         formatter={(value, entry) => {
    //           const { color } = entry as LegendPayload;
    //           return <span style={{ color, fontSize: '16px', borderBottom: '1px solid ' + color }}>{value}</span>;
    //         }}
    //         wrapperStyle={{
    //           paddingLeft: '10px',
    //         }}
    //       />
    //     )}
    //   </BarChart>
    // </ResponsiveContainer>
  );
};

function shadeColor(color: string, percent: number) {
  const colors = color.split(',');
  var r = Number(colors[0]);
  var g = Number(colors[1]);
  var b = Number(colors[2]);
  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}
