import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from 'utilities';
import App from './App';

import 'react-clock/dist/Clock.css';
import 'styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
