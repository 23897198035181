import React from 'react';
import moment from 'moment';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { AppContext, DataSetDto } from 'utilities';
import getChartOptions from './chartOptions';

interface VerticalBarChartProps {
  utilType: string;
  utilTheme: string;
}

export const VerticalBarChart = ({ utilType, utilTheme }: VerticalBarChartProps) => {
  const { metrics, activeChart } = React.useContext(AppContext);
  const metric = metrics?.length > 0 ? metrics.find((x) => x.type === utilType?.toLowerCase()) : null;
  const consumption = metric[activeChart] as DataSetDto;
  const dtFormat = { hourly: 'HH:mm', daily: 'ddd', monthly: 'MMM' };
  const unit = metric?.usage?.unit ? metric.usage.unit.replace('Water ', '').replace('Gas ', '') : 'units';
  const data = [];
  const categories = [];
  let max = 0;
  for (let i = 0; i < consumption.actuals.length; i++) {
    const actual = consumption.actuals[i];
    let act = Math.ceil(actual?.usage) || 0;
    if (actual?.usage > 0 && actual?.usage < 1) {
      act = Number(actual?.usage.toFixed(2));
    }
    data.push(act);
    const event = moment(actual.event).format(dtFormat[activeChart]);
    categories.push(event);
    if (act > max) {
      max = act;
    }
  }
  if (max > 100) {
    max += 10;
  }

  const series = [
    {
      type: 'column',
      data: data,
    },
  ];
  const options = getChartOptions(utilTheme, unit, max);
  // options.chart.marginBottom = 40;
  options.series = series;
  options.chart.type = 'column';
  // @ts-ignore
  options.xAxis.categories = categories;
  options.xAxis.tickInterval = 1;
  options.xAxis.type = 'category';
  // options.xAxis

  React.useEffect(() => {
    Highcharts.charts.forEach((x) => {
      if (x) {
        x.reflow();
      }
    });
  });

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        allowChartUpdate={true}
        immutable={true}
        options={options}
      />
    </div>
  );
};
