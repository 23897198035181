import * as React from 'react';
import { Fade } from 'react-awesome-reveal';
import { WeatherDto } from 'utilities';

import { ReactComponent as WeatherIcon } from 'assets/weather.svg';
import styles from './weather-tile.module.scss';

interface WeatherTileProps {
  color: string;
  weather?: WeatherDto;
  activeChart?: string;
}

export const WeatherTile = ({ color, weather, activeChart }: WeatherTileProps) => {
  const firstEvent = weather.events[0];
  const avgTemp = Math.round((firstEvent.temp.min + firstEvent.temp.max) / 2);
  const avgHumidity = Math.round((firstEvent.humidity.min + firstEvent.humidity.max) / 2);

  React.useEffect(() => {
    let item = null;
    let opts = { type: 'delayed', duration: 600, selfDestroy: true };
    setTimeout(() => {
      // @ts-ignore
      item = new Vivus(`weather`, opts);
    }, 0);
    return () => {
      if (item) {
        item.destroy();
      }
    };
  }, [activeChart]);

  return (
    <div className={`tile ${styles.weatherTile}`} style={{ backgroundColor: `rgb(${color})` }}>
      <Fade>
        <div className={`${styles.largeDoubleText}`}>
          <div className={styles.largeTextValue}>
            {avgTemp}
            <sup>&#x2103;</sup>
          </div>
          <div className={styles.spacer}>/</div>
          <div className={styles.largeTextValue}>
            {avgHumidity}
            <sup>%</sup>
          </div>
        </div>
        <div className={styles.mediumText}>Temperature / Humidity</div>
        <div className={`${styles.backgroundImage} Weather`} />
        <div className={`${styles.usageIcon}`}>
          <WeatherIcon />
        </div>
        <div className={styles.textContent}>
          <div className={`large-text ${styles.largeText}`}>Weather</div>
          <label className={`small-text ${styles.smallText}`}>Today</label>
        </div>
      </Fade>
    </div>
  );
};
