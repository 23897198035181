import * as React from 'react';
import moment from 'moment';
import Clock from 'react-clock';
import { AppContext } from 'utilities';

import styles from './header.module.scss';

export const Header = ({}) => {
  const { dashboardName } = React.useContext(AppContext);
  const now = moment();
  const [date, setDate] = React.useState(now);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setDate(() => moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!dashboardName) {
    return null;
  }
  // @ts-ignore
  // const appVersion = global.appVersion || '';
  return (
    <header className={styles.header}>
      <div className={styles.labels}>
        <h1 className="h2">{dashboardName}</h1>
      </div>
      <div className={styles.logo} />
      <div className={styles.date}>
        <h2 className="h6">{date.format('dddd')}</h2>
        <h2 className="h6">{date.format('DD.MM.YYYY')}</h2>
      </div>
      <div className={styles.clock}>
        <Clock
          className="clock"
          value={date.toDate()}
          size={window.innerWidth >= 2159 ? 90 : 60}
          renderSecondHand={false}
          renderMinuteMarks={false}
          hourMarksLength={20}
          hourMarksWidth={2}
          hourHandWidth={2}
          hourHandOppositeLength={0}
          minuteHandWidth={2}
          minuteHandOppositeLength={0}
        />
      </div>
    </header>
  );
};

export default Header;
