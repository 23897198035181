import * as React from 'react';

import styles from './loader.module.scss';

interface LoaderProps {
  fullscreen?: boolean;
  apiError?: boolean;
  style?: any;
}
export const Loader = ({ fullscreen, apiError, style }: LoaderProps) => (
  <div className={`${fullscreen ? styles.fullscreen : styles.loader} loader `} style={style}>
    {fullscreen && (
      <>
        {apiError && (
          <div className={styles.content}>
            <div className={styles.logo} />
            <p>
              Oops! We're having some trouble getting your details. <br />
              We’ll retry this again shortly.
            </p>
          </div>
        )}
        {!apiError && <div className={`${styles.logo} ${styles.pulse}`} />}
      </>
    )}
  </div>
);

export default Loader;
