import { AxiosRequestConfig, AxiosResponse } from 'axios';

const SEPARATOR = '//**//';
const CACHE_INTERVAL = 1000 * 60 * 59;
const CACHE_WEATHER_INTERVAL = 1000 * 60 * 60 * 24;

function store(key: string, value: string) {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`;
  sessionStorage.setItem(key, finalValue);
}

interface IsValidResponse {
  isValid: boolean;
  value?: string;
}

// Returns true if an object has been stored using the store method
// and have yet not expired
function isValid(key: string): IsValidResponse {
  const value = sessionStorage.getItem(key);
  if (value === null) {
    return {
      isValid: false,
    };
  }
  const values = value.split(SEPARATOR);
  const timestamp = Number(values[1]);
  if (Number.isNaN(timestamp)) {
    return {
      isValid: false,
    };
  }
  const date = new Date(timestamp);
  if (date.toString() === 'Invalid Date') {
    return {
      isValid: false,
    };
  }
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const cacheTime = key.indexOf('weather') > -1 ? CACHE_WEATHER_INTERVAL : CACHE_INTERVAL;

  // for weather, if the cache DAY is different, refetch
  // otherwise use cache time
  if (key.indexOf('weather') > -1 && now.getDate() !== date.getDate()) {
    sessionStorage.removeItem(key);
    return {
      isValid: false,
    };
  } else if (diff < cacheTime) {
    return {
      isValid: true,
      value: values[0],
    };
  } else {
    sessionStorage.removeItem(key);
    return {
      isValid: false,
    };
  }
}

export const responseHandler = (response: AxiosResponse<any>): AxiosResponse<any> => {
  // eslint-disable-next-line no-constant-condition
  if (response.config.method === 'GET' || 'get') {
    if (response.config.url) {
      console.log('storing in cache');
      cache.store(response.config.url, JSON.stringify(response.data));
    }
  }
  return response;
};

export const errorHandler = (error: any) => {
  if (error.headers.cached === true) {
    console.log('got cached data in response, serving it directly');
    return Promise.resolve(error);
  }
  return Promise.reject(error);
};

export const requestHandler = (request: AxiosRequestConfig) => {
  // eslint-disable-next-line no-constant-condition
  if (request.method === 'GET' || 'get') {
    const checkIsValidResponse = cache.isValid(request.url || '');
    if (checkIsValidResponse.isValid) {
      console.log('serving cached data');
      request.headers.cached = true;
      request.data = JSON.parse(checkIsValidResponse.value || '{}');
      return Promise.reject(request);
    }
  }
  return request;
};

export const clearCache = () => {
  sessionStorage.clear();
};

export const cache = {
  store,
  isValid,
};
