import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-awesome-reveal';
import { AppContext } from 'utilities';
import { Header, Footer, Loader, Mask } from 'components';
import { ContentPage, HomePage, NotFoundPage } from 'views';

export const App = () => {
  const {
    backgroundColor,
    backgroundImageL,
    backgroundImageP,
    footerLeftImageL,
    footerRightImageL,
    loading: appLoading,
    notFound,
    apiError,
    customPages,
    activePage,
  } = React.useContext(AppContext);
  let scaleViewport = false;
  if (window.outerWidth < 1080) {
    scaleViewport = true;
  } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    scaleViewport = true;
  }
  const viewport = scaleViewport ? 'width=1080' : 'width=device-width, initial-scale=1';

  if (notFound) {
    return <NotFoundPage />;
  } else {
    return (
      <>
        <Helmet>
          <meta name="viewport" content={viewport} />
        </Helmet>
        <Loader fullscreen={true} apiError={apiError} style={{ display: appLoading ? 'flex' : 'none' }} />
        <Fade style={{ position: 'relative', zIndex: 10 }}>
          <Header />
        </Fade>
        {!appLoading && (
          <main>
            {customPages && customPages[activePage - 1] ? (
              <ContentPage data={customPages[activePage - 1]} />
            ) : (
              <HomePage />
            )}
          </main>
        )}

        <Fade style={{ position: 'relative', zIndex: 12 }}>
          <Footer footerLeftImage={footerLeftImageL} footerRightImage={footerRightImageL} />
        </Fade>

        <Mask
          backgroundColor={backgroundColor}
          backgroundImageL={backgroundImageL}
          backgroundImageP={backgroundImageP}
        />
      </>
    );
  }
};

export default App;
